
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import produce from 'immer';

import Layout from '../components/layout';
import { PageTitle } from '../components/title';

import { GridRow } from '../components/widgets/grid';
import { Table, labelRow } from '../components/blocks/table';

const reducer = (acc, {name, details: { period, definition, inactivity, cost: { price }}}) => {
  return produce(acc, draft => {
    labelRow(draft, 'name', name, undefined, 0);
    labelRow(draft, 'price', `£${price}`, 'Price', 1);
    labelRow(draft, 'period', `${period}`, 'Billing method', 2);
    labelRow(draft, 'interval', 'Monthly', 'Billing cycle', 3);
    labelRow(draft, 'definition', definition, 'Charge basis', 4);
    labelRow(draft, 'inactivity', inactivity, 'Inactive members', 5);
    labelRow(draft, 'direction', 'Inbound and outbound', 'Call direction', 6);
    labelRow(draft, 'switch', 'anytime', 'Switch plans', 7);
  });
};

const Billing = () => {

  const { allPricing } = useStaticQuery(query);
  const { nodes: [{ billing, plans }] } = allPricing;

  const data = _.reduce(plans, reducer, {});

  return (
    <div>
      <Helmet title="Billing | SwitchSmith">
        <link rel="canonical" href="https://switchsmith.com/download" />
      </Helmet>

      <PageTitle title='Billing'/>

      <GridRow>
        <h2>Plan Choice</h2>
      </GridRow>

      <Table data={data} bodyIdx={1}/>

      <GridRow>

        <div className="mt4 mb5">
          <h2>Payment Details</h2>

          <ul>
            {billing.map((b, idx) =>
              <li key={idx}>{b}</li>
            )}
          </ul>

        </div>

      </GridRow>

    </div>
  );
}

const BillingPage = () =>
  <Layout>
    <Billing />
  </Layout>

export default BillingPage;

export const query = graphql`
  query {
    allPricing {
      nodes {
        billing
        commons {
          description
          attribute
        }
        plans {
          name
          details {
            cost {
              price
              min
            }
            period
            definition
            inactivity
            numbers
            features
            sweetspot
            best
          }
        }
      }
    }
  }
`
