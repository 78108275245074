import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';

import { GridRow } from '../widgets/grid';

export const labelRow = (table, key, value, label, priority) => {
  if (!table[key]) {
    table[key] = [priority, key === 'icon', label, value];
  } else {
    table[key].push(value);
  }
}

const RowLabel = ({value}) => {
  const link = value && value.url != undefined;
  if (link) {
    const { url, label } = value;
    return <LinkCell label={label} link={url} />
  }
  else {
    return <div className="tl">{value}</div>
  }
}

const TextCell = ({value, header}) => {
  if (header) {
    return (<div className="tc-ns"><h3>{value}</h3></div>);
  } else {
    return (<div className="tc-ns ml3-ns mr3-ns">{value}</div>);
  }
}

const LinkCell = ({link, label}) => <Link to={link}>{label}</Link>

const ImageCell = ({value}) =>
  <div className="flex justify-around">
    <img className="h3 h4-ns" src={value} />
  </div>

const ButtonCell = ({label, onClick}) =>
  <div className="flex justify-around">
    <div onClick={onClick}>
      <a className="f6 grow hover-yellow no-underline br-pill ph3 pv2 mb2 dib white bg-mid-gray">{label}</a>
    </div>
  </div>

const Cell = ({children}) =>
  <div className="col-xs-8 col-sm-4 col-md-4 col-lg-4">
    {children}
  </div>

const PlanCell = ({row, col, idx, bodyIdx}) => {

  if (row[1]) {
    return <Cell><ImageCell value={row[col]} /></Cell>;
  } else {
    const action = row[col] && row[col].action === true;
    if (action) {
      const { onClick, label } = row[col];
      return <Cell><ButtonCell label={label} onClick={onClick} /></Cell>;
    }
    return <Cell><TextCell value={row[col]} header={idx < bodyIdx} /></Cell>
  }
}

const SmPlanRow = ({ row, col, last, idx, bodyIdx }) =>
  <div className="row">
    <div className="col-xs-12 col-sm-0 col-md-0 col-lg-0">
      <div className={`row ${idx > 0 && 'h3'} ${!last && idx > bodyIdx - 2 && 'bb'} bb-1 b--black-10 flex flex-column items-center`}>

        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <RowLabel value={row[2]} />
        </div>

        <PlanCell row={row} col={col} idx={idx} bodyIdx={bodyIdx} />

      </div>
    </div>
  </div>

const LgPlanRow = ({row, last, idx, bodyIdx}) =>
  <div className="row">
    <div className="col-xs-0 col-sm-12 col-md-12 col-lg-12">
      <div className={`row ${idx > 0 && 'h3'} ${!last && idx > bodyIdx - 2 && 'bb'} bb-1 b--black-10 flex flex-column items-center`}>

        <div className="col-sm-4 col-md-4 col-lg-4">
          <RowLabel value={row[2]} />
        </div>

        <PlanCell row={row} col={3} idx={idx} bodyIdx={bodyIdx} />
        <PlanCell row={row} col={4} idx={idx} bodyIdx={bodyIdx} />

      </div>
    </div>
  </div>

export const Table = ({data, bodyIdx}) => {

  const entries = Object.entries(data).map(([_,value]) => value);
  const sorted = _.orderBy(entries, s => s[0], ['asc']);

  return (
    <>
      <GridRow>

        {sorted.map((row, idx) =>
          <div key={idx}>
            <LgPlanRow row={row} idx={idx} bodyIdx={bodyIdx} last={sorted.length - 1 == idx} />
          </div>
        )}

        {sorted.map((row, idx) =>
          <div key={idx}>
            <SmPlanRow col={3} row={row} idx={idx} bodyIdx={bodyIdx} last={sorted.length - 1 == idx} />
          </div>
        )}

      </GridRow>

      <GridRow>

        {sorted.map((row, idx) =>
          <div key={idx}>
            <SmPlanRow col={4} row={row} idx={idx} bodyIdx={bodyIdx} last={sorted.length - 1 == idx} />
          </div>
        )}

      </GridRow>
    </>
  );
}